import React from "react";
import Sketch from "react-p5";
import "../App.css";


const CircleSketch = () => {

    let wv = 50;
    let th = 30;
    let num = 20;
    let n = 5;
    var cent = [];
    var fullR = 0;
    var colors = [];
    var rots = [];
    var ths = [];
    var hs = [];
    var t = 0;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(400, 400).parent(canvasParentRef);

             
        for(var i = 0; i < n*num; i++) {
            colors.push(p5.color(p5.random(256),p5.random(256),p5.random(256), p5.random(50,150)));
            ths.push(p5.random(-th*.5,th));
        }
        
        for(var i = 0; i < n; i++) {
            cent.push(-50);
        }
        
        cent[0] = 75;
        
        var temp = [];
        for(var i = 0; i < n; i++) {
            
            temp.push(i);
            let c = p5.random(cent[i]-20,cent[i]+20);
            hs.push(p5.random(-wv,wv) - c);
            hs.push(p5.random(-wv,wv) - c);
            hs.push(p5.random(-wv,wv) - c);
            hs.push(p5.random(-wv,wv) - c);
            hs.push(p5.random(-wv,wv) - c);
        }
        
        while(temp.length>0){
            let rand = p5.floor(p5.random(0,temp.length));
            rots.push(p5.random(temp[rand]*(360/n)-15,temp[rand]*(360/n)+15));
            //console.log(temp[rand]);
            temp.splice(rand,1);
        }
        
        fullR = p5.random(0,90);
    }

    const draw = p5 => {
        p5.background(colors[1]);
        p5.translate(p5.width/2, p5.height/2);
        p5.angleMode(p5.DEGREES);
        
        p5.rotate(fullR);
        for(var b = 0; b < n; b++) {
            p5.push();
            var h = [];
            h.push(-50);
            h.push(hs[b*5]);
            h.push(hs[b*5 + 1]+ 10*p5.sin(.5*p5.mouseY));
            h.push(hs[b*5 + 2] + 5*p5.cos(t)+7*p5.sin(.5*p5.mouseX));
            h.push(hs[b*5 + 3] + 10*p5.cos(t)+3*p5.sin(.5*p5.mouseX)+ 4*p5.sin(.5*p5.mouseY));
            h.push(hs[b*5 + 4] + 7*p5.sin(.5*p5.mouseX)+ 5*p5.sin(.5*p5.mouseY));
            h.push(-50);

            p5.rotate(rots[b]);
            for(var j = 0; j < num; j++){
                p5.noStroke();
            //stroke(255);
            p5.fill(colors[b*num + j]);
            p5.beginShape();
            p5.curveVertex(-p5.width,p5.height);
                for(let i = 0; i < h.length; i++) {
                //h[i] += 15 + ths[b*n + j] + 2*sin(t);
                if(b%2 == 0) {
                    h[i] += 15 + ths[b*n + j] + 2*p5.sin(t);
                } else {
                    h[i] += 15 + ths[b*n + j] + 2*p5.cos(t);
                }
                p5.curveVertex(i*(p5.width/5) - p5.width/2,h[i]);
                }
                p5.curveVertex(p5.width,p5.height);
                p5.endShape(p5.CLOSE);

            }
            p5.pop();
        }
        
        t += 2;
        
        p5.noFill();
        p5.stroke(249);
        p5.strokeWeight(150);
        p5.circle(0,0, p5.width*1.3);
  
    }

    return (
        <>
            <Sketch setup={setup} draw={draw} />
        </>
    );
}
export default CircleSketch;