import React from "react";
import '../../App.css';
import './Query.css';
import Sketch from 'react-p5';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Tab from "../../text/Tab";
import '../../styles/Text.css';
import TextLink from "../../text/TextLink";
import axios from 'axios';
import {reshape} from "mathjs";
import SyncLoader from "react-spinners/ClipLoader";

function CNNQuery() {

  const [guess, setGuess] = useState(0);
  const [p5ref, setRef] = useState();

  const [isLoading, setLoading] = useState(false);

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(280, 280).parent(canvasParentRef);
    p5.pixelDensity(1);
    p5.background(0);
    setRef(p5);

  }

  const draw = p5 => {
    if (p5.mouseIsPressed) {
      p5.strokeWeight(20);
      p5.stroke(255);
      p5.line(p5.mouseX, p5.mouseY, p5.pmouseX, p5.pmouseY);
    }
  }

  const clearDrawing = () => {
    p5ref.background(0);
  }


  const pixelate = async () => {
    var br = [];

    p5ref.loadPixels();
    
    for(var row = 0; row < 20; row++) {
      for(var col = 0; col < 20; col++) {
        
        var newX = col*14;
        var newY = row*14;
        
        var num = 0;
        var num2 = 0;
        
        for(var y = newY; y < 14 + newY; y++) {
          for(var x = newX; x < 14 + newX; x++) {
            
            var index = (x + y * p5ref.width) * 4;
            num += p5ref.pixels[index];
            num2++;
          }
        }
        
        num = num/num2;

        br[row*20 + col] = [num];
        
        for(var y = newY; y < 14 + newY; y++) {
          for(var x = newX; x < 14 + newX; x++) {
            
            var index = (x + y * p5ref.width) * 4;
            p5ref.pixels[index] = num;
            p5ref.pixels[index+1] = num;
            p5ref.pixels[index+2] = num;
            p5ref.pixels[index+3] = 255;

          }
        }
      }
    }

    var br2 = reshape(br,[20,20])

    var myArr = new Array(28);
    for(var i = 0; i < 28; i++) {
      myArr[i] = new Array(28).fill(0);
    }

    for(var row = 4; row < 24; row++) {
      for(var col = 4; col < 24; col++) {
        myArr[row][col] = br2[row-4][col-4];
      }
    }


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: reshape(myArr, [784,1]) })
    };
    
    setLoading(true);
    const response = await axios.post('/.netlify/functions/queryNN', requestOptions);
    setGuess(response.data);
    setLoading(false);
    

    p5ref.updatePixels();
  }
  const boldStyle = {
      fontWeight: "bold",
  }

  const override = {
    height: "40px",
  };

  const h2Style = {
    margin: "0px"
  };

  const loadingGuess = () => {
    if(isLoading) {
      return <>
      <div style={override}>
        <SyncLoader color="#2a5a8c" speedMultiplier={0.5}/>
        </div>
      </>;
    } else {
      return <>
      <div style={override}>
      <h2 style={h2Style}>Guess: {guess}</h2>
      </div>
      </>;
    }
  }

  return (
    <>
      <Sketch setup={setup} draw={draw} />
      <div className="button-container">
        <button class="generalButton" onClick={pixelate}>
            Make Guess
        </button>
        <button class="generalButton" onClick={clearDrawing}>
            Clear Drawing
        </button>
      </div>
      {loadingGuess()}
      <p class="paragraph"><Tab/><span style={boldStyle}>How to use:</span> draw any digit (0-9) and click ‘Make Guess’. Try to make sure your drawing takes the full height of the box. 
      A guess will be made after a second or two and you should hopefully see the digit you drew above. 
      You will know the guess has been made when your drawing becomes pixelated. If you are reading this, I am still working on my CNN article which will be out soon!
      </p>
    </>
  );
}

export default CNNQuery;