import '../styles/Text.css';

function Tab() {

    return (
      <>
          <span id="tab"/>
      </>
    );
  }
  
  export default Tab;