import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import CustomImage from "../components/customImage";
import Tab from "../text/Tab";
import TextLink from "../text/TextLink";

const About = () => {
  return (
    <>
      <div id="mainPage" class="mainContainer">
        <div class="mainPageHeader">
          <p id="pageName">About</p>
          <hr />
          <div id="belowHR">
            <Link id="backButton" class="mainLinks" to="/">
              Home
            </Link>
          </div>
        </div>

        <div className="writing">
          <p class="paragraph">
            <Tab />
            Hello! My name is Tyler Cosgrove. I'm currently interested in
            machine learning, software engineering, and golf. Go visit the{" "}
            <TextLink
              to="https://tylercosgrove.com/projects"
              text="Projects"
            />{" "}
            page to see what I’m working on! Here is a picture of my dog:
          </p>
        </div>

        <CustomImage
          src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/1ea7d70c-127b-43dd-0b5a-4323994d7100/public"
          maxHeight="500px"
        />
      </div>
    </>
  );
};

export default About;
