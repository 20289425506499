import '../styles/Text.css';

function Spacer({space=10}) {

  const s = {
    width: '100%',
    height: `${space}px`,
  }

    return (
      <>
          <div style={s}/>
      </>
    );
  }
  
  export default Spacer;