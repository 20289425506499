import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
//import { getAnalytics } from "firebase/analytics"
const firebaseConfig = {
  apiKey: "AIzaSyBFKjIUF3RLGHkErKSAthUjTNx-fn978qw",
  authDomain: "drawingboard-5d1ee.firebaseapp.com",
  databaseURL: "https://drawingboard-5d1ee-default-rtdb.firebaseio.com",
  projectId: "drawingboard-5d1ee",
  storageBucket: "drawingboard-5d1ee.appspot.com",
  messagingSenderId: "521281246275",
  appId: "1:521281246275:web:d9598ea0d0f44c9716b0f6",
  measurementId: "G-DJ0MCLHS9L",
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const db = getDatabase(app);
