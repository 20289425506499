import React from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { useState, useEffect } from "react";
import "../App.css";
import CircleSketch from "./CircleSketch";
import MySketch2 from "./MySketch";

const Home = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const sty = isMobile
    ? {
        position: "absolute",
        margin: "0 auto",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "-1",
      }
    : {
        position: "absolute",
        margin: "0",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "20%",
        zIndex: "-1",
      };

  return (
    <>
      <div className="mainContainer" data-theme={theme}>
        <div className="titleContainer">
          <p id="titleName">Tyler Cosgrove</p>
          <div id="linkContainer">
            <ul id="homeLinkContainer">
              <li class="homeLink">
                <Link class="mainLinks" to="/projects">
                  Projects
                </Link>
              </li>
              <li class="homeLink">
                <Link class="mainLinks" to="/about">
                  About
                </Link>
              </li>
              <li class="homeLink">
                <Link class="mainLinks" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <ul id="homeLinkContainer2">
              <li class="homeLink">
                <a
                  className="mainLinks"
                  href="https://notes.tylercosgrove.com/"
                  target="_blank"
                >
                  Notes
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div style={sty}>
          <CircleSketch />
        </div>
      </div>
    </>
  );
};

export default Home;
