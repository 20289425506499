import React from "react";
import { Link } from 'react-router-dom';
import '../App.css';
import Dog from '../extras/dog.JPG';
import Instagram from '../extras/images/instagram.png';
import LinkedIn from '../extras/images/linkedIn.png';
import Twitter from '../extras/images/twitter.png';
import {useState} from "react";

const Contact = () => {

    const [showMessage, setShow] = useState(false);
    const [message, setMessage]= useState("Click to copy to clipboard");

    const copyEmail = () => {
        setMessage("Copied!");
        navigator.clipboard.writeText("tylercosgrove42@gmail.com")
    }

    const hovering = () => {
        setShow(true);
        setMessage("Click to copy to clipboard");
    }


    const leaveHovering = () => {
        setShow(false);
    }
    

    const emailMessage = {
        visibility: showMessage ? "visible" : "hidden",
        opacity: showMessage ? "1" : "0",
    };

    const emailMeAt = {
        margin: "0px"
    };

    

    return (
        <>
            <div id="mainPage" class="mainContainer">
                <div class="mainPageHeader">
                    <p id="pageName">Contact</p>
                    <hr />
                    <div id="belowHR">
                        <Link id="backButton" class="mainLinks" to="/">Home</Link>
                    </div>
                </div>


                <div id="socialContainer">
                    <Social imagePath={Instagram} to="https://www.instagram.com/tylercosg/"/>
                    <Social imagePath={LinkedIn} to="https://www.linkedin.com/in/tyler-cosgrove-a1b35122a"/>
                    <Social imagePath={Twitter} to="https://twitter.com/tylercosgrove11"/>
                </div>

                <div id="emailContainer">
                    <p style={emailMeAt}>Email me at:</p>
                    <p id="email" onMouseLeave={leaveHovering} onMouseEnter={hovering} onClick={copyEmail}>tylercosgrove42@gmail.com</p>
                    <p id="emailMessage" style={emailMessage}>{message}</p>
                </div>


            </div>
        </>
    );
}



const Social = ({imagePath, to}) => {
    const imageStyle = {
        width:"150px",
        height:"150px"
    };

    return (
        <>
            <a class="textLink" href={to} target="_blank" rel="noopener noreferrer">
            <img style={imageStyle} src={imagePath} />
            </a>
        </>
    );
}

export default Contact;