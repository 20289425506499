import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from "./main_pages/Home.js";
import About from "./main_pages/About.js";
import Contact from "./main_pages/Contact.js";
import Page from './pages/PortfolioPage.js';
import allPages from './pages/allPages.js';
import Portfolio2 from './main_pages/Portfolio2.js';

function App() {

  return (
    <>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/projects" element={<Portfolio2 />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            

            {allPages.map(page => (<Route path={'/projects/' + page.slug} element={<Page page={page} />}/>))}

            
            
          </Routes>
        </Router>
      </>
  );
}

export default App;
