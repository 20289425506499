import '../styles/Text.css';
import { Link } from 'react-router-dom';

function TextLink({to, text}) {

    return (
      <>
        <a class="textLink" href={to} target="_blank" rel="noopener noreferrer">{text}</a>
      </>
    );
  }
  
  export default TextLink;