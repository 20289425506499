import { db } from "./firebase.js";
import { set, ref } from "firebase/database";
import { useState, useEffect } from "react";
import { onValue } from "@firebase/database";
import "./DrawingApp.css";

const Square = ({ index, currColor }) => {
  const [thisColor, setColor] = useState("white");

  const mystyle = {
    backgroundColor: thisColor,
    height: "10px",
    width: "10px",
  };

  useEffect(() => {
    onValue(ref(db, index.toString()), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((newColor) => {
          setColor(newColor);
        });
      }
    });
  }, []);

  const changeColor = () => {
    set(ref(db, index.toString()), {
      title: currColor,
    });
  };

  return (
    <>
      <div style={mystyle} onClick={changeColor}></div>
    </>
  );
};

export default Square;
