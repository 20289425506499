import { React, useState } from "react";
import "../App.css";
import Tab from "../text/Tab";
import "../styles/Text.css";
import CustomImage from "../components/customImage";
import TextLink from "../text/TextLink";
import Spacer from "../text/Spacer";
import Italic from "../text/Italic";
import axios from "axios";
import SyncLoader from "react-spinners/ClipLoader";

function MnistDiffusion() {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const newImage = async () => {
    setLoading(true);
    setImages([]);
    try {
      const response = await axios.get(
        "https://flask-service.rg6ojd155hjfu.us-east-2.cs.amazonlightsail.com/getimage"
      );
      setImages(response.data);
      setLoading(false);
      setIndex(199);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <>
      <div className="mnistImageContainer">
        {isLoading && (
          <div>
            <p>generating a new number</p>
            <SyncLoader color="#6b7280" speedMultiplier={0.5} />
          </div>
        )}

        {images.length != 0 && <PixelImage data={images[index]} />}
      </div>
      <button className="generalButton" onClick={newImage} disabled={isLoading}>
        Generate
      </button>
      <input
        type="range"
        min="0"
        max="199"
        value={index}
        onChange={(event) => {
          setIndex(event.target.value);
        }}
      />
      <p class="paragraph">
        Seeing that I haven't made a post in a while, I thought it'd be nice to
        make a small update, almost like a devlog. I'm currently working on
        building music generation models, mostly using diffusion. I should be
        able to ship a first version of the final tool next month, but in the
        meantime I trained this tiny model on the MNIST dataset. It's certainly
        not perfect, but it is small enough to run on CPU, meaning essentailly
        free to host (but also pretty slow).
      </p>
      <p className="paragraph">
        If you aren't familiar with diffusion models, it's a type of ML
        architecture that learns to slowly remove noise from images, thereby
        generating novel ones. You can look at each step in process with the
        slider.
      </p>
      <p className="paragraph">
        Keep a look out for my next post, it should be a cool one!
      </p>
    </>
  );
}

const PixelImage = ({ data }) => {
  const renderPixel = (intensity) => {
    const color = `rgb(${intensity[0]}, ${intensity[0]}, ${intensity[0]})`;
    return (
      <div
        style={{ width: "10px", height: "10px", backgroundColor: color }}
      ></div>
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(28, 10px)",
      }}
    >
      {data.map((row) => row.map((value) => renderPixel(value)))}
    </div>
  );
};

export default MnistDiffusion;
