import Square from "./Square.js";
import './DrawingApp.css';

const Board = ({currColor}) => {

    const newRow = (start, num) => {
        var row = [];
        for(var i = start; i < start+num; i++) {
            row.push(<Square index={i} currColor={currColor}/>);
        }
        return row;
    }

    //var row = newRow(0,10);

    let h = 50;
    let w = 80;

    const drawingTable = [];
    for(var i = 0; i < h; i++) {
        drawingTable.push(
            <div class="flexbox-container">
                {newRow(i*w,w)}
            </div>
        );
    }

    return (
        <>
            <div id="board-container">
                {drawingTable}
            </div>
        </>
    );
}

export default Board;