import React from "react";
import '../../App.css';
import './Query.css';
import Sketch from 'react-p5';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Tab from "../../text/Tab";
import '../../styles/Text.css';
import TextLink from "../../text/TextLink";
import axios from 'axios';

function Query() {

  const [guess, setGuess] = useState(0);
  const [p5ref, setRef] = useState();

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(280, 280).parent(canvasParentRef);
    p5.pixelDensity(1);
    p5.background(0);
    setRef(p5);

  }

  const draw = p5 => {
    if (p5.mouseIsPressed) {
      p5.strokeWeight(18);
      p5.stroke(255);
      p5.line(p5.mouseX, p5.mouseY, p5.pmouseX, p5.pmouseY);
    }
  }

  const clearDrawing = () => {
    p5ref.background(0);
  }


  const pixelate = async () => {
    var br = [];

    p5ref.loadPixels();
    
    for(var row = 0; row < 28; row++) {
      for(var col = 0; col < 28; col++) {
        
        var newX = col*10;
        var newY = row*10;
        
        var num = 0;
        var num2 = 0;
        
        for(var y = newY; y < 10 + newY; y++) {
          for(var x = newX; x < 10 + newX; x++) {
            
            var index = (x + y * p5ref.width) * 4;
            num += p5ref.pixels[index];
            num2++;
          }
        }
        
        num = num/num2;

        br[row*28 + col] = [num];
        
        for(var y = newY; y < 10 + newY; y++) {
          for(var x = newX; x < 10 + newX; x++) {
            
            var index = (x + y * p5ref.width) * 4;
            p5ref.pixels[index] = num;
            p5ref.pixels[index+1] = num;
            p5ref.pixels[index+2] = num;
            p5ref.pixels[index+3] = 255;

          }
        }
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: br })
    };
    
    const response = await axios.post('/.netlify/functions/queryNN', requestOptions);
    
    setGuess(response.data);
    
    p5ref.updatePixels();
  }

  const boldStyle = {
      fontWeight: "bold",
  }


  return (
    <>
      <Sketch setup={setup} draw={draw} />
      <div className="button-container">
        <button class="generalButton" onClick={pixelate}>
            Make Guess
        </button>
        <button class="generalButton" onClick={clearDrawing}>
            Clear Drawing
        </button>
      </div>
      <h2>Guess: {guess}</h2>

      <p class="paragraph"><Tab/><span style={boldStyle}>How to use:</span> draw any digit (0-9) and click ‘Make Guess’. 
      A guess will be made after a second or two and you should hopefully see the digit you drew above. 
      You will know the guess has been made when your drawing becomes pixelated. Read my article <TextLink to="https://tylercosgrove.com/projects/how-neural-nets-work-part-1-forward-propagation" text="here"/> on how it works!
      </p>
    </>
  );
}

export default Query;