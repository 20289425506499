import React from "react";
import "../App.css";
import "../styles/PortfolioStyle.css";
import DrawingApp from "./DrawingApp/DrawingApp.js";
import Query from "./NeurNetP1/Query";
import CNNQuery from "./NeurNetP1/CNNQuery";
import NeurNetP2 from "./NeurNetP2";
import CustomJSONparser from "./JSONParser/jsonParser";
import NeurNetP3 from "./NeurNetP3";
import Piet from "./Piet";
import RSA from "./RSA";
import Penrose from "./Penrose/Penrose";
import temp from "./markdown/temp.md";
import go from "./markdown/go.md";
import pagerank from "./markdown/pagerank.md";
import MarkdownParser from "./MarkdownParser";
import aiTakeover from "./markdown/aitakeover.md";
import caveman from "./markdown/caveman.md";
import Movies from "./Movies/movies.js";
import rps from "./markdown/rps.md";
import infinitepages from "./markdown/infinitepages.md";
import moviesVis from "./markdown/moviesVis.md";
import stoic from "./markdown/stoic.md";
import coolpapers from "./markdown/coolpapers.md";
import farcaster from "./markdown/farcaster.md";
import mootr from "./markdown/mootr.md";
import MnistDiffusion from "./mnistDiffusion";
import rubiks from "./markdown/rubiks.md";

class SinglePage {
  constructor(title, date, caption, pageContent, time = 0) {
    this.title = title;
    this.date = date;
    this.caption = caption;
    this.pageContent = pageContent;
    this.time = Math.floor(time / 238);
  }

  get slug() {
    let temp = this.title
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
    return temp;
  }
}

let allPages = [];

allPages.push(
  new SinglePage(
    "Rubiks Cube Solver",
    "June 3, 2024",
    "Alright!",
    <MarkdownParser inFile={rubiks} />
  )
);
allPages.push(
  new SinglePage(
    "MNIST Diffusion",
    "January 21, 2024",
    "Alright!",
    <MnistDiffusion />
  )
);
allPages.push(
  new SinglePage(
    "Scooter Ridesharing ",
    "December 8, 2023",
    "Alright!",
    <MarkdownParser inFile={mootr} />
  )
);
allPages.push(
  new SinglePage(
    "Farcaster Visualization",
    "October 4, 2023",
    "Alright!",
    <MarkdownParser inFile={farcaster} />
  )
);
allPages.push(
  new SinglePage(
    "Cool Tech Papers",
    "July 16, 2023",
    "Alright!",
    <MarkdownParser inFile={coolpapers} />
  )
);
allPages.push(
  new SinglePage(
    "Daily Stoic Quote",
    "May 29, 2023",
    "Alright!",
    <MarkdownParser inFile={stoic} />
  )
);
allPages.push(
  new SinglePage(
    "Movie Visualization",
    "May 28, 2023",
    "Alright!",
    <MarkdownParser inFile={moviesVis} />
  )
);
allPages.push(
  new SinglePage(
    "Infinite Pages",
    "April 22, 2023",
    "Alright!",
    <MarkdownParser inFile={infinitepages} />
  )
);
allPages.push(
  new SinglePage(
    "Rock Paper Scissors in Web3",
    "April 8, 2023",
    "Alright!",
    <MarkdownParser inFile={rps} />
  )
);
allPages.push(
  new SinglePage(
    "Every Movie I Have Ever Watched, Ranked",
    "March 30, 2023",
    "Alright!",
    <Movies />
  )
);
allPages.push(
  new SinglePage(
    "Caveman Chatbot",
    "March 10, 2023",
    "Alright!",
    <MarkdownParser inFile={caveman} />
  )
);
allPages.push(
  new SinglePage(
    "Is This Time Different?",
    "February 25, 2023",
    "Alright!",
    <MarkdownParser inFile={aiTakeover} />,
    1078
  )
);
allPages.push(
  new SinglePage(
    "How Google's PageRank Algorithm Works",
    "February 9, 2023",
    "Alright!",
    <MarkdownParser inFile={pagerank} />,
    1359
  )
);
allPages.push(
  new SinglePage(
    "Online Go Game",
    "January 28, 2023",
    "Alright!",
    <MarkdownParser inFile={go} />
  )
);
allPages.push(
  new SinglePage(
    "What is NOSTR?",
    "January 22, 2023",
    "Alright!",
    <MarkdownParser inFile={temp} />,
    1096
  )
);
allPages.push(
  new SinglePage(
    "Aperiodic Tilings (Penrose Tiles)",
    "December 30, 2022",
    "Alright!",
    <Penrose />,
    867
  )
);
allPages.push(
  new SinglePage(
    "RSA Encryption and Generating Primes",
    "December 5, 2022",
    "Can you see this?",
    <RSA />,
    1104
  )
);
allPages.push(
  new SinglePage(
    "Piet: Write Programs with Images",
    "October 30, 2022",
    "Can you see this?",
    <Piet />,
    1067
  )
);
allPages.push(
  new SinglePage(
    "Convolutional Neural Net (CNN) Digit Classification Demo",
    "August 26, 2022",
    "Can you see this?",
    <CNNQuery />
  )
);
allPages.push(
  new SinglePage(
    "How Neural Nets Work: Part 2 - Backward Propagation",
    "August 2, 2022",
    "Can you see this?",
    <NeurNetP3 />,
    1820
  )
);
allPages.push(
  new SinglePage(
    "Custom JSON Parser",
    "July 25, 2022",
    "Alright!",
    <CustomJSONparser />
  )
);
allPages.push(
  new SinglePage(
    "How Neural Nets Work: Part 1 - Forward Propagation",
    "July 20, 2022",
    "Can you see this?",
    <NeurNetP2 />,
    1205
  )
);
allPages.push(
  new SinglePage(
    "Neural Net Digit Classification Demo",
    "July 13, 2022",
    "Here is the description!",
    <Query />
  )
);
allPages.push(
  new SinglePage(
    "Live Drawing Board",
    "June 4, 2022",
    "Here is the description!",
    <DrawingApp />
  )
);

export default allPages;
