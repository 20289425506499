import React from "react";
import { Link } from 'react-router-dom';
import '../App.css';
import '../styles/PStyle.css';
import allPages from "../pages/allPages";
import Spacer from "../text/Spacer";

const Portfolio2 = () => {

    return (
        <>
            <div id="mainPage" class="mainContainer">
                <div class="mainPageHeader">
                        <p id="pageName">Projects</p>
                        <hr />
                        <div id="belowHR">
                            <Link id="backButton" class="mainLinks" to="/">Home</Link>
                        </div>
                </div>
                <div id="listContainer" class="centered">
                    {allPages.map(page => (<PortfolioItem2 page={page}/>))}
                </div>
            </div>
        </>
    );
}


const PortfolioItem2 = ({page}) => {

    const timeToRead = page.time == 0 ? "" : " | " + page.time + " MIN READ";

    return (
        <>
            <div id="itemContainer">
                <p id="itemDate">{page.date}{timeToRead}</p>
                <Link id="itemTitle" to={'/projects/' + page.slug} id="itemTitle">{page.title}</Link>
            </div>
            
        </>
    );
}

export default Portfolio2;