import React from "react";
import { Link } from 'react-router-dom';
import '../App.css';
import '../styles/PStyle.css';

const Page = ({page}) => {

    const timeToRead = page.time == 0 ? <></> : <p id="portfolioPageTime">{page.time} MIN READ</p>;

    return (
        <>
            <div id="portfolioPageContainer" class="mainContainer">
                <div id="portfolioPageHeader">
                    <p id="portolioPageName">{page.title}</p>
                    <hr id="mainLine" />
                    <div id="underHR">
                        <p id="portfolioPageDate">{page.date}</p>
                        <Link id="portfolioPageBack" class="mainLinks" to="/projects">Back to projects</Link>
                    </div>
                        {timeToRead}
                </div>
                
                
                {page.pageContent}
            </div>
        </>
    );
}

export default Page;