import './DrawingApp.css';
import '../../App.css';
import Board from "./Board.js";
import {useState} from "react";
import ColorPicker from './ColorPicker';
import Tab from '../../text/Tab';
import TextLink from '../../text/TextLink';
import TextCode from '../../text/TextCode';
import '../../styles/Text.css';

function DrawingApp() {

    const [currColor, setCurrColor] = useState("gray");
  
  
    return (
      <>
          <Board currColor={currColor}/>
          <ColorPicker color={currColor} setNewColor={setCurrColor}/>

          <div className="writing">
            
            <p class="paragraph"><Tab/>
            Inspired by <TextLink to="https://www.reddit.com/r/place/?cx=703&cy=1313&px=199&ts=1649112460185" text="r/place"/>, 
            this is a pixel mosaic editable by anyone and everyone. Any changes you make will instantly update everyone else's board without them having to refresh the page. 
            Simply pick a color from the given ones, or write a HEX code or an HTML color in the box and press 'Change Color'. 
            Try <TextCode text="lime"/> or <TextCode text="#536e4d"/>.</p>
          </div>
      </>
    );
  }
  
  export default DrawingApp;