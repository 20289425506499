import { useState, useEffect } from 'react';
import './DrawingApp.css';
import '../../App.css';

const ColorPicker = ({color, setNewColor}) => {
    
    const [thisColor, setColor] = useState("");
    const handleColorChange = (e) => {
        setColor(e.target.value);
    }

    const changeColor = () => {
        setNewColor(thisColor);
    }

    const currColorStyle = {
        backgroundColor: color,
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        border: "2px solid black",
    }

    const colors = [
        "#EB144C",
        "#FF6900",
        "#FCB900",
        "#00D084",
        "#8ED1FC",
        "#0693E3",
        "#9900EF",
        "#F78DA7",
        "#FFFFFF",
        "#2B2B2B",
    ];

    const colorChoices = [];
    for(var i = 0; i < colors.length; i++) {
        colorChoices.push(
            <div style={{padding: "5px"}}>
                <ColorOption color={colors[i]} setNewColor={setNewColor}/>
            </div>
        );
    }


    return (
        <>  
            <div id="color-picker-container">
                <div class="color-picker">
                    <div style={{paddingInline: "20px"}}>
                        <div style={currColorStyle}></div>
                    </div>

                    {colorChoices}
                    
                </div>
                <p>Enter HEX or HTML color below:</p>
                <input  id="colorPickerInput" type="text" value={thisColor} onChange={handleColorChange} placeholder="HEX or HTML color"/>
                <button class="generalButton" onClick={changeColor}>Change Color</button>
            </div>
        </>
    );
}

const ColorOption = ({color, setNewColor}) => {
    const currColorStyle = {
        backgroundColor: color,
    }

    const setColor = () => {
        setNewColor(color);
    }

    return (
        <>  
            <div style={currColorStyle} id="indivColor" onClick={setColor}>
            </div>
        </>
    );
}

export default ColorPicker;