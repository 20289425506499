// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { getDatabase } from "firebase/database";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const secondaryAppConfig = {
  apiKey: "AIzaSyBOefhPi8n4IeGofoQU-azDQa1OupRvXeg",
  authDomain: "movies-d7d43.firebaseapp.com",
  projectId: "movies-d7d43",
  storageBucket: "movies-d7d43.appspot.com",
  messagingSenderId: "255460822312",
  appId: "1:255460822312:web:67b6fa0dbe0027f2f0141d",
  measurementId: "G-LN4GG3H4Y8",
  databaseURL: "https://movies-d7d43-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
//let app2;
let app2;
try {
  app2 = getApp("secondary");
} catch (error) {
  if (error.code === "app/no-app") {
    app2 = initializeApp(secondaryAppConfig, "secondary");
  } else {
    console.error(
      "Unexpected error when getting or initializing Firebase app:",
      error
    );
  }
}
//const app2 = initializeApp(secondaryAppConfig, "secondary");
//const analytics = getAnalytics(app);
export const db2 = getDatabase(app2);
