import React from "react";
import '../../App.css';
import { useState, useRef } from 'react';
import '../../styles/Text.css';
import './Penrose.css'
import Sketch from 'react-p5';
import reactCSS from 'reactcss'
import { FaExpand, FaCompress } from "react-icons/fa";
import { BsFillTriangleFill, BsCircleFill } from "react-icons/bs";
import { ChromePicker, SketchPicker } from 'react-color';
import Spacer from "../../text/Spacer";
import CustomImage from "../../components/customImage";
import TextLink from "../../text/TextLink";

function Penrose() {
    const [seed, setSeed] = useState(1);
    const [p5ref, setRef] = useState();
    const [n, setN] = useState(3);
    const [isKites, setKites] = useState(true);
    const [big,setBig] = useState(true);
    const [circle, setCircle] = useState(true);

    const leftB = useRef();
    const rightB = useRef();

    const reset = () => {
        setSeed(Math.random());
    }

    const changeN = (e) => {
        setN(e.target.value);
        reset();
    }



    const goldenRatio = (1 + Math.sqrt(5)) / 2;

    const setup = (p5, canvasParentRef) => {

        class Tile {
            constructor(color,A,B,C) {
              this.A = p5.createVector(A.x,A.y);
              this.B = p5.createVector(B.x,B.y);
              this.C = p5.createVector(C.x,C.y);
              this.color = color;
            }
        }

        function divide2(tiles) {
            var toReturn = [];
            
            for(var i = 0; i < tiles.length; i++) {
              var curr = tiles[i];
              if(curr.color == 0) {
                var Q = curr.B.copy().sub(curr.A).div(goldenRatio).add(curr.A);
                var R = curr.C.copy().sub(curr.B).div(goldenRatio).add(curr.B);
                toReturn.push(new Tile(1,R,Q,curr.B));
                toReturn.push(new Tile(0,Q,curr.A,R));
                toReturn.push(new Tile(0,curr.C,curr.A,R));
                
              } else {
                
                var P = curr.A.copy().sub(curr.C).div(goldenRatio).add(curr.C);
                toReturn.push(new Tile(1,curr.B,P,curr.A));
                toReturn.push(new Tile(0,P,curr.C,curr.B));
                
              }
            }
            return toReturn;
          }

          function divide(tiles) {
            var toReturn = [];
            
            for(var i = 0; i < tiles.length; i++) {
              var curr = tiles[i];
              if(curr.color == 0) {
                
                var P = curr.B.copy().sub(curr.A).div(goldenRatio).add(curr.A);
                toReturn.push(new Tile(0,curr.C,P,curr.B));
                toReturn.push(new Tile(1,P,curr.C,curr.A));
                
              } else {
                
                var Q = curr.A.copy().sub(curr.B).div(goldenRatio).add(curr.B);
                var R = curr.C.copy().sub(curr.B).div(goldenRatio).add(curr.B);
                toReturn.push(new Tile(1,R,curr.C,curr.A));
                toReturn.push(new Tile(1,Q,R,curr.B));
                toReturn.push(new Tile(0,R,Q,curr.A));
                
              }
            }
            return toReturn;
            
          }

        p5.createCanvas(400, 400).parent(canvasParentRef);
        p5.background(50);
        setRef(p5);

        var colorC1 = p5.createVector(color.r, color.g, color.b);
        var colorC2 = p5.createVector(color2.r, color2.g, color2.b);
        if(isKites) {
            var temp = colorC1;
            colorC1 = colorC2;
            colorC2 = temp;
        }

        var border = 255;
        var tiles = [];
        var type = isKites ? 1 : 0;
        var num = n;

        var scale= big ? 300 : 200;
        var num2 = circle ? 10 : 1;

        if(!circle) {
          scale = 400;
        }

        for(var i = 0; i < num2; i++) {
            //r * (math.cos(phi) + math.sin(phi)*1j)
            var A = p5.createVector(0,0);
            var B = p5.createVector(scale*p5.cos((2*i - 1) * p5.PI / 10), scale*p5.sin((2*i - 1) * p5.PI / 10));
            var C = p5.createVector(scale*p5.cos((2*i + 1) * p5.PI / 10), scale*p5.sin((2*i + 1) * p5.PI / 10));
            
            if(i%2 == 0) {
                if(type==0)
                    tiles.push(new Tile(0,A,C,B));
                else
                    tiles.push(new Tile(0,C,A,B));
            } else {
                if(type==0)
                    tiles.push(new Tile(0,A,B,C));
                else
                    tiles.push(new Tile(0,B,A,C));
            }
        }

        
        for(var i = 0; i < num; i++) {
            if(type==0)
              tiles=divide(tiles);
            else
              tiles=divide2(tiles);
        }
        

        if(circle) {
          p5.translate(p5.width/2, p5.height/2);
        } else {

          p5.translate(0, p5.height/2);
        }

        for(var i = 0; i < tiles.length; i++) {
            if(tiles[i].color == 0)
              p5.fill(colorC1.x, colorC1.y, colorC1.z);
            else
              p5.fill(colorC2.x, colorC2.y, colorC2.z);
            
            p5.noStroke();
            p5.beginShape();
            p5.vertex(tiles[i].A.x,tiles[i].A.y);
            p5.vertex(tiles[i].B.x,tiles[i].B.y);
            p5.vertex(tiles[i].C.x,tiles[i].C.y);
            p5.endShape(p5.CLOSE);
        }

        for(var i = 0; i < tiles.length; i++) {
            if(tiles[i].color == 0)
                p5.stroke(colorC1.x, colorC1.y, colorC1.z);
            else
                p5.stroke(colorC2.x, colorC2.y, colorC2.z);
          
            p5.line(tiles[i].C.x,tiles[i].C.y,tiles[i].B.x,tiles[i].B.y);
            
            p5.stroke(border);
            p5.line(tiles[i].C.x,tiles[i].C.y,tiles[i].A.x,tiles[i].A.y);
            p5.line(tiles[i].A.x,tiles[i].A.y,tiles[i].B.x,tiles[i].B.y);
        }
    
    }


    const draw = p5 => {

    }

    const newType = (kite) => {
        setKites(kite);
        leftB.current.style.background = kite ? "var(--button-color2)" : "var(--button-color1)";
        rightB.current.style.background = kite ? "var(--button-color1)" : "var(--button-color2)";

        reset();
    }

    const setExpand = (big) => {
      setBig(big);
      reset();
    }

    const setShape = (shape) => {
      setCircle(shape);
      console.log("clicked");
      reset();
    }


    const growShrink = () => {
      return circle ? (big ? <FaCompress id="ontop" onClick={() => setExpand(false)}/> : <FaExpand id="ontop" onClick={() => setExpand(true)}/>) : <></>;
    }

    const changeShape = () => {
      return circle ? <BsFillTriangleFill id="ontop" onClick={() => setShape(!circle)}/> : <BsCircleFill id="ontop" onClick={() => setShape(!circle)}/>;
    }


  const [displayColorPicker, setDisplay] = useState(false);
  const [color, setColor] = useState({r: '200',g: '16',b: '80',a: '1',});

  const handleClick = () => {
    setDisplay(!displayColorPicker);
  }

  const handleClose = () => {
    setDisplay(false);
    reset();
  }

  const handleChange = (color) => {
    setColor(color.rgb);
  }


  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '36px',
        borderRadius: '15%',
        background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ 1 })`,
      },
      swatch: {
        padding: '5px',
        display: 'inline-block',
        cursor: 'pointer',
        marginRight:"15px",
      },
      popover: {
        position: 'absolute',
        left:'50%',
        right:'50%',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });





  const [displayColorPicker2, setDisplay2] = useState(false);
  const [color2, setColor2] = useState({r: '41',g: '41',b: '99',a: '1',});

  const handleClick2 = () => {
    setDisplay2(!displayColorPicker2);
  }

  const handleClose2 = () => {
    setDisplay2(false);
    reset();
  }

  const handleChange2 = (color) => {
    setColor2(color.rgb);
  }


  const styles2 = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '36px',
        borderRadius: '15%',
        background: `rgba(${ color2.r }, ${ color2.g }, ${ color2.b }, ${ 1 })`,
      },
      swatch: {
        padding: '5px',
        display: 'inline-block',
        cursor: 'pointer',
        marginLeft:'15px'
      },
      popover: {
        position: 'absolute',
        left:'50%',
        right:'50%',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });






  return (
    <>
        <h3 class="paragraph-header">Aperiodic tiling</h3>
        <div className="writing">
            <p class="paragraph">Tiling, sometimes also referred to as tessellation, simply refers to the covering of a plane with simple geometric shapes. Examples of this include checkerboard or honeycomb patterns, which alone are not terribly interesting. What I do find interesting, though, are aperiodic tilings, patterns that do not repeat (the before mentioned patterns would be considered periodic).</p>
        </div>
        <Spacer/>

        <h3 class="paragraph-header">History of aperiodic tilings</h3>
        <div className="writing">
            <p class="paragraph">The first example of aperiodic tilings comes from Hao Wang, who had conjectured that if a group of tiles can tile the plane, the only way to do it was periodical. However, one of his students, Rober Berger, later discovered a set of tiles that disproved Wang’s theorem, and in doing so discovered the first set of aperiodic tiles. His tiles were a set of 20,426 “Wang” dominoes, each a square made of four colored triangles. In order to tile the plane aperiodically, neighboring triangles had to share the same color.</p>
        </div>
        <Spacer/>

        <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/fd48edef-13af-4269-cdbb-26873647bf00/public" maxHeight="200px"/><Spacer/>
        <p class="caption thinner">Image from <TextLink to="https://en.wikipedia.org/wiki/Wang_tile" text="Wikipedia"/></p>
        <div className="writing">
          <p class="paragraph">Berger was able to reduce this number down to 104, and Hans Läuchli was able to get it to just 40. Eventually, other form factors were used, and this number dropped to just two.</p>
      </div>

      <Spacer/>

      <h3 class="paragraph-header">Penrose tilings</h3>
        <div className="writing">
            <p class="paragraph">The most famous example of aperiodic tilings are Penrose tiles, first discovered by legendary physicist and mathematician Roger Penrose in 1974. There are two main sets of Penrose tiles: kites & darts, and thick & thin rhombuses. When placing these tiles, there are some rules that ensure aperiodicity. This can include colored edges that must be paired with like-colored edges, or notches and cutouts (imagine a jigsaw puzzle). 
</p>
        </div>
        <Spacer/>
        <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/2573a903-bc38-4fba-8cd4-e615ddb25200/public" maxHeight="225px"/><Spacer/>

        <h3 class="paragraph-header">Generating Penrose tiles</h3>
        <div className="writing">
            <p class="paragraph">An interesting aspect of Penrose tiles are the numerous ways to generate them. The most obvious of these is putting them together like puzzle pieces, but this is neither very clever nor interesting. Here are two cooler ways to generate the pattern.</p>
        </div>
        <div className="writing connected">
          <p class="paragraph">The first is called inflation (or deflation, depending on your perspective). This method begins with dividing a triangle into smaller sub-triangles. As you might guess, the way in which you divide is crucial, and involves the golden ratio (perhaps a clue to the tiles’ aperiodicity). As you divide, you will find there are two types of triangles, one that you will then divide into two triangles, and one that divides into three. This divisions are as follows:</p>
      </div>
        <Spacer/>
        <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/3ddfe49a-abb1-4839-7894-e4901a3bce00/public" maxHeight="225px"/><Spacer/>


        <div id="p5-container">
            <Sketch setup={setup} draw={draw} key={seed} />
            <div id="button-container">
              {changeShape()}
              {growShrink()}
            </div>
        </div>
        <input type="range" min="0" max="7" value={n} onChange={changeN}/>
        <div className="button-container">
        <button class="generalButton" ref={leftB} onClick={() => newType(true)}>
            Kites &amp; Darts
        </button>
        <button class="generalButton" ref={rightB} onClick={() => newType(false)}>
            Thick &amp; Thin
        </button>
      </div>

      <div>
        <div style={ styles.swatch } onClick={ handleClick }>
          <div style={ styles.color } />
        </div>
        { displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ handleClose }/>
          <ChromePicker color={ color } onChange={ handleChange } />
        </div> : null }

        <div style={ styles2.swatch } onClick={ handleClick2 }>
          <div style={ styles2.color } />
        </div>
        { displayColorPicker2 ? <div style={ styles2.popover }>
          <div style={ styles2.cover } onClick={ handleClose2 }/>
          <ChromePicker color={ color2 } onChange={ handleChange2 } />
        </div> : null }

      </div>


      <div className="writing">
          <p class="paragraph">The second way to create Penrose tiles is using what is called a pentagrid. A pentagrid is similar to a normal grid, except that instead of two sets of parallel lines, it has five. To draw a pentagrid, rotate these sets of lines by a multiple of <span class="math">2π/5</span>. At the intersection of two lines, we can make a diamond with faces normal to these lines.</p>
      </div>

      <Spacer/>

<CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/9921135b-116b-43cf-c06a-9fbe990ef400/public" maxHeight="275px"/><Spacer/>
<p class="caption thinner">Image from <TextLink to="https://www.mathpages.com/home/kmath621/kmath621.htm" text="MathPages"/></p>

<div className="writing">
          <p class="paragraph">Now, if we increase the size of the diamonds and slightly move them such that they perfectly tile the plane, we see we have created a Penrose tiling.</p>
      </div>

      <Spacer/>

<CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/2e171a1a-6b11-410f-ac21-1350dbfe3200/public" maxHeight="300px"/><Spacer/>

<h3 class="paragraph-header">Proof of aperiodicity</h3>
        <div className="writing">
            <p class="paragraph">It might be difficult to believe that such simple rules can arrange such simple shapes in a way that they will never repeat. Unfortunately, there is no single proof that applies to all aperiodic tilings that shows they never repeat, instead each one is highly individualized. That being said, proving that Penrose tiles are aperiodic is not very hard. Let’s go back to inflation. First, scroll up to the sketch I made, and, beginning with zero, slowly increase the number of iterations, counting the ratio of thin to thick rhombuses (or kites to darts) each time. What you’ll find is that this ratio approaches the golden ratio, approximately <span class="math">1.618</span>. We can easily prove this by noticing that the number of thin and thick rhombuses follows the fibonacci sequence (<span class="math">1</span>, <span class="math">1</span>, <span class="math">2</span>, <span class="math">3</span>, <span class="math">5</span>, <span class="math">8</span>, <span class="math">13</span>, etc.). We can assign variables to the number of the types of rhombuses, and write a formula for the ratio between them. Due to the fact that they follow the fibonacci sequence, we can also write a formula for the ratio for the next iteration of inflation. Finally, because our tiling is in infinite space, we can set these two formulas equal to each other.
</p>
        </div>
        <Spacer/>
        <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/2a2daf4d-b88b-448d-f42b-db33bd5c3700/public" maxHeight="225px"/><Spacer/>
        <div className="writing">
            <p class="paragraph">Now, we can easily solve this equation by first dividing the right side by <span class="math">B</span>. Now, we can assign <span class="math">X</span> to <span class="math">A/B</span>, and solve for <span class="math">X</span>.
</p>
        </div>
        <Spacer/>
        <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/1df2b1d8-56cf-44a5-6b75-a3c6857b4000/public" maxHeight="225px"/><Spacer/>
        <div className="writing">
            <p class="paragraph">What we find is that <span class="math">X</span> (or the ratio of thin to thick rhombuses), is equal to the golden ratio, which we know to be irrational. If this ratio was rational, that would mean that we could divide our tiling into smaller subtilings, one that has, for example, four thin rhombuses and five thick ones. After these four thin and five thick rhombuses, our theoretically rational tiling would repeat, and we would find another subtitling with these parameters. 
</p>
        </div>

        <div className="writing connected">
            <p class="paragraph">However, if the ratio is irrational, like we just found it was, we will never be able to divide our infinite tiling into smaller subtilings, because it being irrational, by definition, means that it cannot be simplified into a ratio of two whole numbers. Since we know that our tiling can never be divided into smaller equal subtilings, the pattern must never repeat. Thanks for reading, and have a great day!
</p>
        </div>
    </>
  );
}

export default Penrose;