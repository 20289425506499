import React from "react";
import '../App.css';
import Tab from "../text/Tab";
import '../styles/Text.css';
import CustomImage from "../components/customImage";
import TextLink from "../text/TextLink";
import Spacer from "../text/Spacer";
import Italic from "../text/Italic";

function RSA() {

  return (
    <>
        <h3 class="paragraph-header">Introduction</h3>
        <div className="writing">
            <p class="paragraph">Everyone knows that encryption is essential for the internet to run in any kind of usable capacity. Without it, there is no privacy, and so on, and so on. But how does it actually work? Well, in 1977, three MIT mathematicians (Ron Rivest, Adi Shamir, and Leonard Adelman) created the first public example of what is known as asymmetric encryption (symmetric encryption has been around for millenia, and was all the rage when Caesar was in power). I say public because a few years prior a guy named Clifford Cocks discovered the same system but, as he was working for the UK government, kept it secret. Luckily for us though, these three colleagues published their findings in <Italic text="A Method for Obtaining Digital Signatures and Public-Key Cryptosystems"/>, and now we can play online slots. Yes, technically you can use symmetric encryption and just do a <TextLink to="https://en.wikipedia.org/wiki/Diffie%E2%80%93Hellman_key_exchange" text="key exchange"/>, but a key exchange itself is asymmetric, so there's no real purpose in doing this.</p>
        </div>
        <div className="writing connected">
          <p class="paragraph">For the uninitiated, asymmetric encryption means that two parties do not have to share a predetermined private key in order to share a private message. This article is specifically about RSA encryption, the etymology you can likely figure out. However, before we can fully understand how the RSA cryptosystem works, there are a few basic ideas we must master.</p>
      </div>
      <Spacer/>
    


      <h3 class="paragraph-header">Basics</h3>
      <div className="writing">
          <p class="paragraph">The first of these is the modulus operator, generally shortened to <span class="math">mod</span>. Simply put, this operator returns the remainder after division. </p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/503cb90d-383d-44d8-1901-e031ef7c9f00/public" maxHeight="110px"/>
      <div className="writing">
          <p class="paragraph">Next is the idea of being coprime(sometimes called relatively prime or mutually prime). Two numbers are coprime if they do not share a factor other than <span class="math">1</span>.</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/0196cb91-dc68-44c5-e3c1-0db05fd3f600/public" maxHeight="200px"/>
      <div className="writing">
          <p class="paragraph">The final important idea is Euler’s totient function(also known as the Phi function), denoted as <span class="math">ϕ(n)</span>. This function returns the number of integers that are (1) less than <span class="math">n</span>, and (2) coprime to <span class="math">n</span>. Calculating <span class="math">ϕ(8)</span> would look like this:</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/5da591a3-0949-4ed5-b8b9-58038fcf8800/public" maxHeight="220px"/>
      <div className="writing">
          <p class="paragraph">An important aspect of the totient function is that for a prime <span class="math">p</span>, <span class="math">ϕ(p) = p - 1</span>. Also, this function is multiplicative, meaning that for two integers <span class="math">x</span> and <span class="math">y</span>, <span class="math">ϕ(xy) = ϕ(x)ϕ(y)</span>.</p>
      </div>
      <Spacer/>

      <h3 class="paragraph-header">Encryption</h3>
      <div className="writing">
          <p class="paragraph">Now that we have all of the basics understood, we can begin the actual RSA algorithm. The first step is to choose two primes, <span class="math">p</span> and <span class="math">q</span>. We will choose small numbers to make it easier for ourselves, but generally these are huge. Later, we will discuss how these are actually chosen, but for now, <span class="math">p = 23</span>, and <span class="math">q = 41</span>. Now, we multiply <span class="math">p</span> and <span class="math">q</span> to get <span class="math">N</span>. In our case, <span class="math">N = 943</span>. The next step involves taking the Euler Totient of <span class="math">N</span>, which, because the Totient function is multiplicative, is very easy. Now, we find a number <span class="math">e</span> that is coprime with the result of this calculation.</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/e21fc5a9-0882-4062-af22-a23779102200/public" maxHeight="220px"/>
      <div className="writing">
          <p class="paragraph">As long as <span class="math">p</span> and <span class="math">q</span> are kept private, we can publish both <span class="math">N</span> and <span class="math">e</span> anywhere. Now that we have <span class="math">N</span> and <span class="math">e</span>, we can successfully encrypt a message! To do this, we will first turn our message into a number, and call it <span class="math">M</span>. Let’s say that number is <span class="math">35</span>. Now, raise <span class="math">M</span> to the <span class="math">e</span>, and <span class="math">mod N</span>.</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/c0447578-52e3-4165-1b79-deec2b47d600/public" maxHeight="190px"/>
      <div className="writing">
          <p class="paragraph">We now have successfully encrypted a message using RSA encryption!</p>
      </div>
      <Spacer/>


      <h3 class="paragraph-header">Decryption</h3>
      <div className="writing">
          <p class="paragraph">Now that we have an encrypted message, we want to decrypt it. Currently, we have three public numbers, <span class="math">N</span>, <span class="math">e</span>, and <span class="math">C</span>, which are <span class="math">943</span>, <span class="math">7</span>, and <span class="math">545</span>, respectively. We now want to find a <span class="math">d</span> such that <span class="math">(ed)mod((p-1)(q-1)) = 1</span>. Notice that <span class="math">p</span> and <span class="math">q</span> are not public numbers, which means finding <span class="math">d</span> is very difficult, and would require some brute force program. However, since we know <span class="math">p</span> and <span class="math">q</span>, we can solve the equation and get an answer of <span class="math">d = 503</span>.</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/55ffea78-cf46-4d95-8a7c-01be7f6cce00/public" maxHeight="220px"/>
      <div className="writing">
          <p class="paragraph">Now that we know <span class="math">d</span>, we can finally decrypt the message by raising <span class="math">C</span> to <span class="math">d</span> and <span class="math">mod N</span>. This will result in our original message, <span class="math">M</span>.</p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/152d67a4-c26e-481f-5279-1dc4d5484d00/public" maxHeight="170px"/>
      <div className="writing">
          <p class="paragraph">Raising <span class="math">545</span> to the <span class="math">503rd</span> power results in a massive number, but luckily there are <TextLink to="https://sites.millersville.edu/bikenaga/number-theory/exponentiation-ciphers-and-rsa-ciphers/exponentiation-ciphers-and-rsa-ciphers.html" text="tricks"/> that make this much faster. That is the entire process of encryption and decryption! Now I will go over how RSA is actually used, and some problems to watch out for. </p>
      </div>
      <Spacer/>


      <h3 class="paragraph-header">Actual values</h3>
      <div className="writing">
          <p class="paragraph">Because we have to turn our message into a number, encrypting a long message quickly requires massive amounts of calculation, which is why actual messages are generally not encrypted directly with RSA. Instead, we use RSA encryption to encrypt a private key that will then allow us to decrypt some symmetric cryptosystem, such as AES or a salt. Also, our practice simulation used very small numbers so it would be easier to tell what was going on, but in reality, these would be cracked instantly. In the real world, <span class="math">N</span> is commonly <span class="math">1024</span> bits, which means that <span class="math">p</span> and <span class="math">q</span> are both around <span class="math">512</span> bits long each. Generally, the number 65537 is used as <span class="math">e</span>. This is because it is both a large prime and easy to compute in binary, as it is <span class="math">2^16 + 1</span>. </p>
      </div>
      <Spacer/>

      <h3 class="paragraph-header">Why RSA works</h3>
      <div className="writing">
          <p class="paragraph">RSA encryption is difficult to break because it requires factoring huge numbers. If a bad actor is able to factor <span class="math">N</span> into <span class="math">p</span> and <span class="math">q</span>, they can calculate everything else. Even on today’s fastest computers, this would still take somewhere in the ballpark of 300 trillion years if we are using standard length keys. However, the emergence of quantum computing is challenging this, and it is likely that in the near future quantum computers will be able to easily crack these cryptosystems. </p>
      </div>
      <Spacer/>

      <h3 class="paragraph-header">Problems with generating primes</h3>
      <div className="writing">
          <p class="paragraph">When generating <span class="math">p</span> and <span class="math">q</span>, there are a few things to keep in mind. The first is their size, as previously mentioned, and the second is how close they are to each other. For example, <span class="math">p</span> and <span class="math">q</span> should not be close to the square root of <span class="math">N</span>. This is because of a clever method used to break RSA that uses Fermat’s factorization method, in which an integer is represented as the difference of two squares. </p>
      </div>
      <CustomImage src="https://imagedelivery.net/UZNAmnq96elr8RlEetSxnQ/e143c0cb-e6dd-4b4c-2bd1-48c9a1df1300/public" maxHeight="120px"/>
      <div className="writing">
          <p class="paragraph">Here, <span class="math">p = a+b</span>, and <span class="math">q = a-b</span>. We start by assigning <span class="math">a</span> to root <span class="math">N</span>, and then incrementing <span class="math">a</span> each time a solution is not found. If <span class="math">p</span> and <span class="math">q</span> are close together, we will be able to find them quite quickly. If they are not close together, though, then this strategy is barely any better than brute force, which is practically impossible to break. </p>
      </div>
      <Spacer/>


      <h3 class="paragraph-header">Conclusion</h3>
      <div className="writing">
          <p class="paragraph">If you'd like to learn more about encryption and the origins of RSA, I encourage you to read the <TextLink to="https://people.csail.mit.edu/rivest/Rsapaper.pdf" text="original paper"/>, which has more details about the specifics of picking certain values. Thanks for reading, and have a nice day! </p>
      </div>
      <Spacer/>
    
    
    </>
  );
}

export default RSA;