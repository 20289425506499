import '../styles/Text.css';

function TextCode({text}) {

    return (
      <>
          <span class="textCode">{text}</span>
      </>
    );
  }
  
  export default TextCode;