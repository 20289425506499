import React from "react";
import '../App.css';
import { storage } from "../external/firebase-image";
import { getDownloadURL, ref } from "@firebase/storage";
import { useEffect, useState } from "react";

const CustomImage = ({src, minHeight="100px", maxWidth = "700px", maxHeight = "700px"}) => {
/*
    const [url, setUrl] = useState();

    useEffect(() => {
        const func = async () => {
            const reference = ref(storage, src);
            await getDownloadURL(reference).then((x) => {
                setUrl(x);
            })
        }

        func();
    }, []);*/

    const imageStyle = {
        minHeight: minHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        boxShadow: "5px 5px 5px #d3d9db"
    };

    return (
        <>
            <img style={imageStyle} src={src}/>
        </>
    );
}

export default CustomImage;