import React from "react";
import "../../App.css";
import "../../styles/Text.css";
import "./movies.css";
import { db2 } from "./firebase.js";
import { useState, useEffect, useContext } from "react";
import { set, ref } from "firebase/database";
import { onValue, off } from "@firebase/database";
import TextLink from "../../text/TextLink";

function Movies() {
  const [ranks, setRanks] = useState([]);

  useEffect(async () => {
    const fetchData = async () => {
      const tempRanks = [];
      const dataRef = ref(db2);

      const callback = (snapshot) => {
        console.log("running");
        const data = snapshot.val();
        if (data !== null) {
          for (const id in data) {
            tempRanks.push(data[id]);
          }
        }

        tempRanks.sort((a, b) => b.rank - a.rank);
        setRanks(tempRanks);
      };

      onValue(dataRef, callback);

      return () => {
        off(dataRef, "value", callback);
      };
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("good");
  }, [ranks]);

  return (
    <>
      <div className="writing">
        <p class="paragraph">
          Here is every movie I have ever watched (at least the ones I remember
          well—probably meaning those I've seen in the past ~3 years) ranked. To
          help me rank them, I built a voting system using a chess-like Elo{" "}
          <TextLink
            to="https://en.wikipedia.org/wiki/Elo_rating_system"
            text="algorithm"
          />{" "}
          so I could put them head-to-head. I hope you think they are neither
          too pretentious nor plain. Let me know what you think!
        </p>
      </div>

      <div id="content-movies">
        <ol>
          {ranks.map((e) => (
            <>
              <div id="entry">
                <li className="main" key={e.id}>
                  <a
                    className="title"
                    href={e.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {e.name}
                  </a>
                </li>
                <p className="subtitle">
                  <span className="gray">
                    {e.year}, {e.director}
                  </span>
                </p>
                <p className="description">
                  {e.description
                    ? e.description.length > 250
                      ? e.description.substring(0, 2500) + "..."
                      : e.description
                    : ""}
                </p>
                <img className="poster" src={e.src} />
              </div>
              <hr />
            </>
          ))}
        </ol>
      </div>
    </>
  );
}

export default Movies;
